//@import '~react-toastify/dist/ReactToastify.css';
@import "./banner.scss";
@import "./fonts/fuentes.css";

// *****************************************************************************
// variables
// *****************************************************************************
 $input-padding-y:12px;
 $input-padding-x:27px;
 $azul:#00a9e0;
 $verde:#5bc500;
 $input-gris:#e9eae8;

.fl{font-family: 'telefonicaextralight';}
.fwb{font-family: "telefonicabold";}


// -------------------------------------------------------------------------
// Main
// -------------------------------------------------------------------------
*{
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: 'telefonicaregular'!important;
  height: 100%;
  overflow: hidden;
  width: 100%;
  color: #50535a;

  input:disabled{
    background: transparent;
  }
}

.ro{border: 1px solid red;}

.textoExclusivo{
  font-weight: 900 !important;
  font-size: 26px !important;
}
.textoUnClick {
  font-weight: 100 !important;
  font-size: 50px !important;
  letter-spacing: -1px;
  line-height: 45px !important;
}

.textoGratis{
  color: #5BC500 !important;
  font-size: 100px !important;
  line-height: 0.5em !important;
}

.textoExclusivoWhite{
  color: white !important;
  font-weight: 900 !important;
  font-size: 26px !important;
  padding-top: 10px;
}
.textoUnClickWhite {
  color: white !important;
  font-size: 60px !important;
  font-family: 'telefonicalight';
  letter-spacing: -1px;
  line-height: 60px !important;
}

.textoGratisWhite{
  color: white !important;
  font-size: 60px !important;
  line-height: 0.5em !important;
}
a,button,.cpointer{

  cursor: pointer;
  &:focus{
    outline: none;
  }
}

button{
  background: transparent;
}

div.cpointer{
  &:hover{
    text-decoration: underline;
  }
}


#app,.App,#root{
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: #bcbcbc;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #bfc3c6;
}

::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #f5f5f5;
}

input{
  color: #86888c;

  &.primary{
    padding: 0.8rem 0.75rem;
  }

}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group{

  label,input {
    padding: $input-padding-y $input-padding-x;
  }

  input{

       background-color: transparent;

       &::-webkit-input-placeholder {
         color: transparent;
       }

       &:-ms-input-placeholder {
         color: transparent;
       }

       &::-ms-input-placeholder {
         color: transparent;
       }

       &::-moz-placeholder {
         color: transparent;
       }

       &::placeholder {
         color: transparent;
       }

       &:not(:placeholder-shown) {
         padding-top: $input-padding-x;
         padding-bottom: $input-padding-y;
       }

       &:not(:placeholder-shown) ~ label {
         padding-top: 0;
         padding-bottom: 0;
         font-size: 12px;
         color: #86888c;
       }
      &:disabled{
        background-color: transparent!important;
      }
     }

  label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    cursor: text; /* Match the input under the label */
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
  }

}

// -------------------------------------------------------------------------
// Utils
// -------------------------------------------------------------------------

.imgr{
  width: 100%;
  height: auto;
  display: block;
}

.imgb{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.f01,.f02{
  display: flex;
  flex-flow:row wrap;
  justify-content: center;
  align-items: center;
}

.f02{flex-flow: column nowrap;}

.algfs{align-items: flex-start;}
.algfe{align-items: flex-end;}
.algs{align-items: stretch;}

.jcfs{justify-content: flex-start;}
.jcfe{justify-content: flex-end;}
.jcsb{justify-content: space-between;}
.jcsa{justify-content: space-around;}

%tr,.tr{
  transition: all 500ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

.dn{display: none!important;}
.op{opacity: 1!important;}

.cb{color: white;}
.cn{color: black;}

.bgg{background-color: #e4e4e4;}
.bgb{background-color: white;}
.bg-azul{background-color: $azul!important;}
.bgt{background-color: $verde!important;}


.wc{width: 100%;}
.hc{height: 100%;}
.hvh{height: 100vh;}

.pa{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

.brd{border-radius: 10px;}

.btn-round{
  border-radius: 25px;
}

.cont-btn-t{
  line-height: 0;
}

.btn-t{
  min-height: 50px;
  border-radius: 4px;
  background-color: $verde;
  color: white;
  font-size: 1.2em;
  line-height: 0!important;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0,0,0,0);
  @extend .tr;

  &:hover{
    box-shadow: 0 0 0 .2em rgba(0,0,0,.1);
  }
}

.btn-b{
  min-height: 50px;
  border-radius: 4px;
  border: 1px solid $verde;
  background-color: white;
  color: $verde;
  font-size: 1.2em;
  line-height: 0;
  cursor: pointer;

  box-shadow: 0 0 0 rgba(0,0,0,0);
  @extend .tr;

  &:hover{
    box-shadow: 0 0 0 .2em rgba(0,0,0,.1);
  }
}

.btn-g{
  min-height: 50px;
  background: white;
}

.loading{
  position: relative;


  &:after,&:before{
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    border-radius: 50%;
    border: 3px solid rgba(255,255,255,.3);
  }

  &:after{
    border-right: 3px solid white;
    animation: girar .75s linear infinite;
  }
}

.loading3{
  position: relative;


  &:after,&:before{
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    border-radius: 50%;
    border: 3px solid rgba(91,197,00,.3);
  }

  &:after{
    border-right: 3px solid $verde;
    animation: girar .75s linear infinite;
  }
}

.campo-icon{
  position: relative;

  input.icon-padding{
    padding-left: 40px;
  }

  input{
    line-height: 45px;
    height: 47px;
    border-radius: 4px;
    background-color: $input-gris;
    border: 1px solid $input-gris;
    width: 100%;

    &:focus{
      outline: none;
      background-color: white;

    }


    //&::-webkit-input-placeholder { /* Edge */
    //  font-style: italic;
    //  font-family: "telefonicaregular_italic";
    //}
    //
    //&:-ms-input-placeholder { /* Internet Explorer 10-11 */
    //  font-style: italic;
    //  font-family: "telefonicaregular_italic";
    //}
    //
    //&::placeholder {
    //  font-style: italic;
    //  font-family: "telefonicaregular_italic";
    //}
  }



  input[type="checkbox"]{
    height: 15px;
    max-height: 15px;
    width: 15px;
    border: 1px solid red;
    padding: 0;
    cursor: pointer;
    line-height: 15px!important;
    position: relative;
    z-index: 2;
    opacity: 0;
  }

  .cont-chulo{
    width: 15px;
    margin: auto;
    position: relative;
  }

  .chulo{
    width: 15px;
    height: 15px;
    box-shadow: 0 0 0 .23em $azul;
    border-radius: 50%;
    position: absolute;
    left: 0;
    z-index: 1;
    top:5px;
  }

  .chuleado{
     background-image: url("/assets/images/check.png");
     background-color: $azul;


    &:after{
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 0 0 .23em $azul;
      border-radius: 50%;
      animation: onda .5s cubic-bezier(0.190, 1.000, 0.220, 1.000) 1;
    }
  }

  @keyframes onda {
    from{
      transform:scale(1);
      opacity:(1);
    }
    to{
      transform:scale(2);
      opacity:(0);
    }
  }

  .icon{
    width: 40px;
    position: absolute;
    top:0;
    left: 0;
    height: 50px;
    text-align: center;
    line-height: 50px;
    z-index: 16;
  }
  .error-icon{
    color: red;
  }

  .error-input{
    background: white;
    border-color: red;
    color: red;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: red;
      opacity:.5; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: red;
      opacity:.5; /* Firefox */
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: red;
      opacity:.5; /* Firefox */

    }
  }

  .error{
    font-style: italic;
    display: block;
    width: 100%;
    text-align: left;
    color:red;
  }



  .react-select__control,.select-error__control{
    background-color: $input-gris;
    border-color: $input-gris;
    position: relative;
    z-index: 10;
    max-height: 50px;
    &:hover{
      border-color: $input-gris;
    }
  }
  .select-error__control{
    border-color: red;
    background-color:white;
  }

  .react-select__control--is-focused,.select-error__control--is-focused{
    background-color: white;
    border-color: $input-gris;
    border-width: 1px;
    box-shadow: none;
    &:hover{
      border-color: $input-gris;
    }
  }

  .select-error__control--is-focused{
    border-color: red;
  }


  .react-select__value-container,.select-error__value-container{
    max-height: 50px;
    padding-left: 40px;
  }

  .select-error__placeholder{
    color: red;
  }


  .react-select__menu,
  .react-select__dropdownIndicator{
    text-align: left;
    z-index: 22;
  }
}

.ciudad{
  position: relative;
  z-index: 100;

}

@keyframes girar {
  0%{transform: translate(-50%,-50%) rotate(0)}
  100%{transform: translate(-50%,-50%) rotate(360deg)}
}


.loadingData{
  border-radius: 4px;
  width: 100%;
  display: block;
  background: linear-gradient(90deg, #e4e4e4, white, #e4e4e4);
  background-size: 600% 600%;


  -webkit-animation: loadingResponse .5s linear infinite ;
  -moz-animation: loadingResponse .5s linear infinite ;
  animation: loadingResponse .5s linear infinite ;
}

@-webkit-keyframes loadingResponse {
  0%{background-position:0% 50%}
  100%{background-position:100% 50%}

}
@-moz-keyframes loadingResponse {
  0%{background-position:0% 50%}
  100%{background-position:100% 50%}

}
@keyframes loadingResponse {
  0%{background-position:0% 50%}
  100%{background-position:100% 50%}

}



// -------------------------------------------------------------------------
// Colors
// -------------------------------------------------------------------------
.fc-gray{
  color: #50535a;
}

.fc-silver{
  color: #b6b7b7;
}
.cv{color: $verde;}
.ca{color: $azul;}
.cg{color: #cbcbcb;}
.cy{color: orange;}
.cr{color: red;}
.bgv{background-color: $verde}
.bga{background-color: $azul}

.opn{
  opacity: 0;
}


// -------------------------------------------------------------------------
// Header
// -------------------------------------------------------------------------

header{
  width: 100%;
  box-shadow: 0  1px 3px rgba(0,0,0,.1);
  z-index: 20;

  .logo-movistar{
    img{
    max-height: 35px;
    width: auto;
    height: auto;
    }
  }
}

// -------------------------------------------------------------------------
// botones de recarga
// -------------------------------------------------------------------------

.btn-amount{
  margin: .5em;
  position: relative;
  z-index: 0;

  img{
    position: relative;
    z-index: 0;
  }

  button{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    cursor: pointer;
    @extend .tr;

    &:hover{
      background: $verde;
      color: white;
      border: 1px solid transparent;
    }

    &:disabled{
      background: rgba(0,0,0,.01);
      color: #999999;
      cursor: not-allowed;
      border: 1px solid #e4e4e4;
    }

    p{
      margin: 0;
    }
  }
}

.max350{
  max-width: 350px;
}

.btn-amount.active{
  button{
    background-color: $verde;
    cursor: initial;
    color: white;

    &:disabled{
      border-color: transparent;
    }
  }
}


.error{
  font-style: italic;
  display: block;
  width: 100%;
  text-align: left;
  color:red;
}

.metodosIcons{
  img{
    max-height: 22px;
    margin-left: 5px;
  }
}


///////////////////////////////
///////////////////////////////
///////////////////////////////
///////////////////////////////

.bala01{
  width: 50px;
  height: 4px;
  background-color: $verde;
  border-radius: 10px;
  animation : Chargin .5s linear infinite;
}

.bala02{
  width: 50px;
  height: 3px;
  background-color: $azul;
  border-radius: 10px;
  margin-bottom: 3px;
  animation : Chargin .7s linear infinite;
}


.bala03{
  width: 30px;
  height: 6px;
  margin-bottom: 5px;
  background-color: $verde;
  border-radius: 10px;
  animation : Chargin .6s linear infinite;
}


@keyframes Chargin {
  0%   { transform  : translateX(-200px); opacity:0;}
  50%  { transform  : translateX(0);      opacity:1;}
  100% { transform  : translateX(200px); opacity:0;}
}


///////////////////////////////
///////////////////////////////
///////////////////////////////
///////////////////////////////


/*Circulos*/

.LoaderBalls {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.LoaderBalls__item {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #5BC500;
  transform: 0.5s;
}
.LoaderBalls__item:nth-child(1) {
  animation: bouncing 0.4s alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95);
  background: rgb(180, 250, 118);
}
.LoaderBalls__item:nth-child(2) {
  animation: bouncing 0.4s 0.1s alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;
  background: rgb(119, 219, 31);
}
.LoaderBalls__item:nth-child(3) {
  animation: bouncing 0.4s 0.2s alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;
  background: #5BC500;
}

@keyframes bouncing {
  0% {
    transform: translate3d(0, 10px, 0) scale(1.2, 0.85);
  }
  100% {
    transform: translate3d(0, -20px, 0) scale(0.9, 1.1);
  }
}

