
$verde : #5bc500;
$azul  : #00a9e0;
$azul2  : #0C273A;
$negro : #555555;
$gris1 : #646464;
$gris2 : #535353;


.cta {color: #00a9e0;}
.ctv {color: $verde;}

h1 b {font-family: "telefonicabold";}

.enlace-banner{
  position: absolute;
  top:0;
  left:0;
  z-index: 9;
  width:100%;
  height:100%;
}

.banner-movil {
  margin-bottom: 1.5em;

  .terminos-movil {
    width: 100%;
    position: absolute;
    bottom: -.5em;
    left: 0;
    text-align: center;

    a {
      color: #999999;
    }
  }
}

.mhc {min-height: 100vh;}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 5px;
  height: 5px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  border-radius:50%;
  background: #cbcbcb;

  button{opacity: 0}
}

.slick-dots li.slick-active{
  background: #2ebd59;
}

.myslider {
  height: 100%;
  .slick-dots {
    position: absolute;
    bottom: 1em;
    width: auto;
    left: 50%
  }
  .slick-list, .slick-track, .slick-slide {
    min-height: 100%;
    height: 100%;
  }
  .slick-slide {
    height: 100%;
    min-height: 100%;

    div {
      &:first-child {
        height: 100%;
      }
    }
  }
}

.cont-slider-movil {
  position: relative;

  .myslider2 {
    height: 100%;

    .slick-dots {
      position: absolute;
      bottom: .7em;
      width: auto;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .terminos {
    position: absolute;
    bottom: .5em;
    width: 100%;
    font-size: 13px;

    a {
      color: white !important;

      &:hover {
        color: white;
      }
    }
  }
}

.bannerMovilNormal {
  position: relative;

  a {
    width: 80%;
    padding: .75em 1em;
    border-radius: 5px;
    background-color: #5bc500;
    color: white !important;
    display: block;
    max-width: 200px;
    position: absolute;
    bottom: 2em;
    left: 50%;
    transform: translateX(-50%);
  }
}


.RT{
  background-image:
          url("/img/banner/Noviembre2020/triple/framents/vector2.png"),
          url("/img/banner/Noviembre2020/triple/framents/vector1.png") !important;
  background-position:
          center bottom,
          center top;
  background-size: 100%, cover;
  background-repeat: no-repeat;
  background-color: white;

  &.TM{
    background-color: $azul;
    background-image:
            url("/img/banner/Octubre2020/triple/framents/mano_y_vector.png"),
            url("/img/banner/Octubre2020/triple/framents/vector_triangulos.png") !important;
    background-position:
            right bottom,
            center 60%;
    background-size: 100%, 100%;

    @media all and (min-width: 1440px){
      background-size: 90%, 100%;
    }
  }
  .texto {
    color: white;
    width: 90%;
    margin-left: 10px;
    text-align: center;

    h1{
      font-size: 2.2em;
      line-height: 1.3em;
      width: 100%;
      max-width: 430px;
      margin: auto;
      font-family: 'telefonicalight';
      b{
        font-family:'telefonicabold';
      }
    }

    p {
      text-align: center;
      border-radius: 25px;
      display: inline-block;
      color: white;
      font-family: "telefonicabold";
    }

    .terminos {
      // position: absolute;
      transform: none;
      bottom: 49%;
      right: -9%;

      a {
        color: white;
        font-size: .8em;
      }
    }

    @media all and (min-width: 1100px){
      p.cb{
        font-size: 1.5em;
      }
      h1.mensaje{
        font-size: 2.8em;
        max-width: 530px !important;
      }
    }

    @media all and (max-width: 900px){
      p.cb{
        font-size: 1em;
        margin-top: 50px !important;
      }
      h1.mensaje{
        font-size: 2em;
        margin-top: 25px !important;
      }
    }

  }

}

.RD{
  background-image:
          url("/img/banner/Noviembre2020/double/framents/bolas_navideñas.png"),
          url("/img/banner/Noviembre2020/double/framents/chica_pegatina.png") !important;
  background-position:
          center top,
          center bottom;
  background-size: 100%, 100%;
  background-repeat: no-repeat;
  background-color: $azul;
  &.TM{
    background-color: $azul;
    background-image:
            url("/img/banner/Octubre2020/double/framents/mano_y_vector.png"),
            url("/img/banner/Octubre2020/double/framents/vector_triangulos.png") !important;
    background-position:
            right bottom,
            center 60%;
    background-size: 100%, 100%;

    @media all and (min-width: 1440px){
      background-size: 90%, 100%;
    }
  }
  .texto {
    color: white;
    width: 90%;
    margin-left: 10px;
    text-align: center;

    h1 {
      font-size: 2.2em;
      line-height: 1.3em;
      width: 100%;
      max-width: 350px;
      margin: auto;
      font-family: 'telefonicalight';

      b {
        font-family: 'telefonicabold';
      }
    }

    p {
      text-align: center;
      font-size: 1.2em;
      font-weight: bold;
      border-radius: 25px;
      display: inline-block;
      font-family: "telefonicabold";
      color:white;
    }

    .terminos {
      // position: absolute;
      transform: none;
      left: 5%;
      bottom: 3%;

      a {
        color: white;
        font-size: .8em;
      }
    }

    @media all and (min-width: 1100px){
      p.cb{
        font-size: 1.5em;
      }
      h1.mensaje{
        font-size: 2.8em;
        max-width: 530px !important;
      }
    }

    @media all and (max-width: 900px){
      p.cb{
        font-size: 1em;
        margin-top: 50px !important;
      }
      h1.mensaje{
        font-size: 2em;
        margin-top: 25px !important;
      }
    }

  }
}

.PE{
  background-image:
          url("/img/banner/Noviembre2020/percentage/framents/vector2.png"),
          url("/img/banner/Noviembre2020/percentage/framents/vector1.png") !important;
  background-position:
          center bottom,
          center top;
  background-size: 100%, cover;
  background-repeat: no-repeat;
  background-color: white;

  .texto {
    color: $azul2;
    text-align: center;
    width: 90%;
    margin-left: 10px;

    h1{
      font-size: 2.2em;
      line-height: 1.3em;
      width: 100%;
      max-width: 319px;
      margin: auto;
      font-family: 'telefonicalight';

      @media all and (min-width: 1600px){
        font-size: 3.4em ;
      }

      b{
        font-family:'telefonicabold';
      }
    }

    p {
      &.cb{
        color: $azul2;
      }
      text-align: center;
      border-radius: 25px;
      display: inline-block;
      font-family: "telefonicabold"
    }

    .terminos {
      /*position: absolute;*/
      transform: none;
      bottom: 5%;
      right: 3%;

      a {
        color: $azul2;
        font-size: .8em;
      }
    }

    @media all and (min-width: 1100px){
      p.cb{
        font-size: 1.5em;
      }
      h1.mensaje{
        font-size: 2.8em;
        max-width: 530px !important;
      }
    }

    @media all and (max-width: 900px){
      p.cb{
        font-size: 1em;
        margin-top: 50px !important;
      }
      h1.mensaje{
        font-size: 2em;
        margin-top: 25px !important;
      }
    }

  }
}

.ES{
  background-image:
          url("/img/banner/Noviembre2020/structure/framents/vector3.png"),
          url("/img/banner/Noviembre2020/structure/framents/vector2.png"),
          url("/img/banner/Noviembre2020/structure/framents/vector1.png") !important;
  background-position:
          center top,
          center bottom,
          center;
  background-size: 100%, 100%, cover;
  background-repeat: no-repeat;
  background-color: $azul;

  .texto {
    color: white;
    width: 100%;
    margin-left: 10px;
    text-align: center;

    h1{
      font-size: 2.2em;
      line-height: 1.4em;
      width: 100%;
      max-width: 360px;
      margin: auto;
      font-family: 'telefonicalight';

    }

    p {
      text-align: center;
      border-radius: 25px;
      display: inline-block;
      color: white;
      font-family: "telefonicabold";
    }

    .terminos {
      // position: absolute;
      transform: none;
      bottom: 49%;
      right: -9%;

      a {
        color: white;
        font-size: .8em;
      }
    }

    @media all and (min-width: 1100px){
      p.cb{
        font-size: 1.5em;
      }
      h1.mensaje{
        font-size: 2.8em;
        max-width: 530px !important;
      }
    }

    @media all and (max-width: 900px){
      p.cb{
        font-size: 1em;
        margin-top: 50px !important;
      }
      h1.mensaje{
        font-size: 2em;
        margin-top: 25px !important;
      }
    }

  }

}

.NA{
   background-image:
           url("/img/banner/agosto2020/footer.png"),
           url("/img/banner/agosto2020/widget.png"),
           url("/img/banner/agosto2020/vector.png"),
           url("/img/banner/agosto2020/mainbg.jpg");
   background-position:
           7% 92%,
           5% 55%,
           right bottom,
           center
;
   background-size:
    30% auto,
    40% auto,
    60% auto,
    cover
;
   background-repeat: no-repeat;

   .texto {
     color: white;
     width: 100%;
     margin-left: 10px;

     h1{
       font-size: 2.2em;
       line-height: 1.3em;
       margin-bottom: 0;
       width:90%;
       font-family: 'telefonicalight';

       @media all and (min-width: 1400px){
         font-size: 3.4em ;
       }
     }

     p {
       text-align: center;
       border-radius: 25px;
       display: inline-block;
       color: white;
       font-family: "telefonicabold";
     }

     .terminos {
       position: absolute;
       bottom: 3%;
       left: 5%;

       a {
         color: white;
         font-size: .8em;
       }
     }
   }
 }

.PP {
  background:
  center bottom url("/img/banner/footer/footer.png"),
  left 75% url("/img/banner/Julio2020/turn-page/framents/mobile.png"),
  center bottom url("/img/banner/Julio2020/turn-page/framents/mountains.png"),
  center top url("/img/banner/Julio2020/turn-page/framents/wind.png") white;
  background-size: 100% auto, 100% auto, 100% auto, 100% auto !important;
  background-repeat:no-repeat;
}








