h1 {
  margin: 0;
  padding: 0;
}

html,
body,
.app {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100vh;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup_inner {
  background: transparent;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  padding: 0;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.close-btn {
  position: absolute;
  cursor: pointer;
  padding: 15px;
  margin-left: 280px;
}