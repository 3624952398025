h1 {
  margin: 0;
  padding: 0;
}

html,
body,
.app {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100vh;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup_inner {
  background: transparent;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  padding: 0;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}

.close-btn {
  position: absolute;
  cursor: pointer;
  padding: 15px;
  margin-left: 280px;
}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 7, 2019 */
@font-face {
  font-family: 'telefonicabold';
  src: url(/static/media/telefonicaweb-bold-webfont.0feb6c2e.eot);
  src: url(/static/media/telefonicaweb-bold-webfont.0feb6c2e.eot?#iefix) format("embedded-opentype"), url(/static/media/telefonicaweb-bold-webfont.566ccfa1.woff2) format("woff2"), url(/static/media/telefonicaweb-bold-webfont.eeffacc5.woff) format("woff"), url(/static/media/telefonicaweb-bold-webfont.99230e78.ttf) format("truetype"), url(/static/media/telefonicaweb-bold-webfont.716b58a9.svg#telefonicabold) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'telefonicaextralight';
  src: url(/static/media/telefonicaweb-extralight-webfont.88496af3.eot);
  src: url(/static/media/telefonicaweb-extralight-webfont.88496af3.eot?#iefix) format("embedded-opentype"), url(/static/media/telefonicaweb-extralight-webfont.24d47835.woff2) format("woff2"), url(/static/media/telefonicaweb-extralight-webfont.80b74761.woff) format("woff"), url(/static/media/telefonicaweb-extralight-webfont.70ff45c7.ttf) format("truetype"), url(/static/media/telefonicaweb-extralight-webfont.977ad00f.svg#telefonicaextralight) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'telefonicahand';
  src: url(/static/media/telefonicaweb-hand-webfont.179ee6c9.eot);
  src: url(/static/media/telefonicaweb-hand-webfont.179ee6c9.eot?#iefix) format("embedded-opentype"), url(/static/media/telefonicaweb-hand-webfont.7b9a9847.woff2) format("woff2"), url(/static/media/telefonicaweb-hand-webfont.669b368f.woff) format("woff"), url(/static/media/telefonicaweb-hand-webfont.a60af2df.ttf) format("truetype"), url(/static/media/telefonicaweb-hand-webfont.ba55d3aa.svg#telefonicahand) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'telefonicalight';
  src: url(/static/media/telefonicaweb-light-webfont.41cc2457.eot);
  src: url(/static/media/telefonicaweb-light-webfont.41cc2457.eot?#iefix) format("embedded-opentype"), url(/static/media/telefonicaweb-light-webfont.024edef3.woff2) format("woff2"), url(/static/media/telefonicaweb-light-webfont.93e04068.woff) format("woff"), url(/static/media/telefonicaweb-light-webfont.7900a0c5.ttf) format("truetype"), url(/static/media/telefonicaweb-light-webfont.3a80d5bc.svg#telefonicalight) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'telefonicaregular';
  src: url(/static/media/telefonicaweb-regular-webfont.dbfa9225.eot);
  src: url(/static/media/telefonicaweb-regular-webfont.dbfa9225.eot?#iefix) format("embedded-opentype"), url(/static/media/telefonicaweb-regular-webfont.0ba3c0a8.woff2) format("woff2"), url(/static/media/telefonicaweb-regular-webfont.fb62e050.woff) format("woff"), url(/static/media/telefonicaweb-regular-webfont.637d6287.ttf) format("truetype"), url(/static/media/telefonicaweb-regular-webfont.1f7e4720.svg#telefonicaregular) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'telefonicaregular_italic';
  src: url(/static/media/telefonicaweb-regularitalic-webfont.aa2bc2c3.eot);
  src: url(/static/media/telefonicaweb-regularitalic-webfont.aa2bc2c3.eot?#iefix) format("embedded-opentype"), url(/static/media/telefonicaweb-regularitalic-webfont.fc04fe73.woff2) format("woff2"), url(/static/media/telefonicaweb-regularitalic-webfont.01515f74.woff) format("woff"), url(/static/media/telefonicaweb-regularitalic-webfont.baf8f550.ttf) format("truetype"), url(/static/media/telefonicaweb-regularitalic-webfont.3c115854.svg#telefonicaregular_italic) format("svg");
  font-weight: normal;
  font-style: normal; }

@charset "UTF-8";
.cta {
  color: #00a9e0; }

.ctv {
  color: #5bc500; }

h1 b {
  font-family: "telefonicabold"; }

.enlace-banner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%; }

.banner-movil {
  margin-bottom: 1.5em; }
  .banner-movil .terminos-movil {
    width: 100%;
    position: absolute;
    bottom: -.5em;
    left: 0;
    text-align: center; }
    .banner-movil .terminos-movil a {
      color: #999999; }

.mhc {
  min-height: 100vh; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 5px;
  height: 5px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  background: #cbcbcb; }
  .slick-dots li button {
    opacity: 0; }

.slick-dots li.slick-active {
  background: #2ebd59; }

.myslider {
  height: 100%; }
  .myslider .slick-dots {
    position: absolute;
    bottom: 1em;
    width: auto;
    left: 50%; }
  .myslider .slick-list, .myslider .slick-track, .myslider .slick-slide {
    min-height: 100%;
    height: 100%; }
  .myslider .slick-slide {
    height: 100%;
    min-height: 100%; }
    .myslider .slick-slide div:first-child {
      height: 100%; }

.cont-slider-movil {
  position: relative; }
  .cont-slider-movil .myslider2 {
    height: 100%; }
    .cont-slider-movil .myslider2 .slick-dots {
      position: absolute;
      bottom: .7em;
      width: auto;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
  .cont-slider-movil .terminos {
    position: absolute;
    bottom: .5em;
    width: 100%;
    font-size: 13px; }
    .cont-slider-movil .terminos a {
      color: white !important; }
      .cont-slider-movil .terminos a:hover {
        color: white; }

.bannerMovilNormal {
  position: relative; }
  .bannerMovilNormal a {
    width: 80%;
    padding: .75em 1em;
    border-radius: 5px;
    background-color: #5bc500;
    color: white !important;
    display: block;
    max-width: 200px;
    position: absolute;
    bottom: 2em;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }

.RT {
  background-image: url("/img/banner/Noviembre2020/triple/framents/vector2.png"), url("/img/banner/Noviembre2020/triple/framents/vector1.png") !important;
  background-position: center bottom, center top;
  background-size: 100%, cover;
  background-repeat: no-repeat;
  background-color: white; }
  .RT.TM {
    background-color: #00a9e0;
    background-image: url("/img/banner/Octubre2020/triple/framents/mano_y_vector.png"), url("/img/banner/Octubre2020/triple/framents/vector_triangulos.png") !important;
    background-position: right bottom, center 60%;
    background-size: 100%, 100%; }
    @media all and (min-width: 1440px) {
      .RT.TM {
        background-size: 90%, 100%; } }
  .RT .texto {
    color: white;
    width: 90%;
    margin-left: 10px;
    text-align: center; }
    .RT .texto h1 {
      font-size: 2.2em;
      line-height: 1.3em;
      width: 100%;
      max-width: 430px;
      margin: auto;
      font-family: 'telefonicalight'; }
      .RT .texto h1 b {
        font-family: 'telefonicabold'; }
    .RT .texto p {
      text-align: center;
      border-radius: 25px;
      display: inline-block;
      color: white;
      font-family: "telefonicabold"; }
    .RT .texto .terminos {
      -webkit-transform: none;
              transform: none;
      bottom: 49%;
      right: -9%; }
      .RT .texto .terminos a {
        color: white;
        font-size: .8em; }
    @media all and (min-width: 1100px) {
      .RT .texto p.cb {
        font-size: 1.5em; }
      .RT .texto h1.mensaje {
        font-size: 2.8em;
        max-width: 530px !important; } }
    @media all and (max-width: 900px) {
      .RT .texto p.cb {
        font-size: 1em;
        margin-top: 50px !important; }
      .RT .texto h1.mensaje {
        font-size: 2em;
        margin-top: 25px !important; } }

.RD {
  background-image: url("/img/banner/Noviembre2020/double/framents/bolas_navideñas.png"), url("/img/banner/Noviembre2020/double/framents/chica_pegatina.png") !important;
  background-position: center top, center bottom;
  background-size: 100%, 100%;
  background-repeat: no-repeat;
  background-color: #00a9e0; }
  .RD.TM {
    background-color: #00a9e0;
    background-image: url("/img/banner/Octubre2020/double/framents/mano_y_vector.png"), url("/img/banner/Octubre2020/double/framents/vector_triangulos.png") !important;
    background-position: right bottom, center 60%;
    background-size: 100%, 100%; }
    @media all and (min-width: 1440px) {
      .RD.TM {
        background-size: 90%, 100%; } }
  .RD .texto {
    color: white;
    width: 90%;
    margin-left: 10px;
    text-align: center; }
    .RD .texto h1 {
      font-size: 2.2em;
      line-height: 1.3em;
      width: 100%;
      max-width: 350px;
      margin: auto;
      font-family: 'telefonicalight'; }
      .RD .texto h1 b {
        font-family: 'telefonicabold'; }
    .RD .texto p {
      text-align: center;
      font-size: 1.2em;
      font-weight: bold;
      border-radius: 25px;
      display: inline-block;
      font-family: "telefonicabold";
      color: white; }
    .RD .texto .terminos {
      -webkit-transform: none;
              transform: none;
      left: 5%;
      bottom: 3%; }
      .RD .texto .terminos a {
        color: white;
        font-size: .8em; }
    @media all and (min-width: 1100px) {
      .RD .texto p.cb {
        font-size: 1.5em; }
      .RD .texto h1.mensaje {
        font-size: 2.8em;
        max-width: 530px !important; } }
    @media all and (max-width: 900px) {
      .RD .texto p.cb {
        font-size: 1em;
        margin-top: 50px !important; }
      .RD .texto h1.mensaje {
        font-size: 2em;
        margin-top: 25px !important; } }

.PE {
  background-image: url("/img/banner/Noviembre2020/percentage/framents/vector2.png"), url("/img/banner/Noviembre2020/percentage/framents/vector1.png") !important;
  background-position: center bottom, center top;
  background-size: 100%, cover;
  background-repeat: no-repeat;
  background-color: white; }
  .PE .texto {
    color: #0C273A;
    text-align: center;
    width: 90%;
    margin-left: 10px; }
    .PE .texto h1 {
      font-size: 2.2em;
      line-height: 1.3em;
      width: 100%;
      max-width: 319px;
      margin: auto;
      font-family: 'telefonicalight'; }
      @media all and (min-width: 1600px) {
        .PE .texto h1 {
          font-size: 3.4em; } }
      .PE .texto h1 b {
        font-family: 'telefonicabold'; }
    .PE .texto p {
      text-align: center;
      border-radius: 25px;
      display: inline-block;
      font-family: "telefonicabold"; }
      .PE .texto p.cb {
        color: #0C273A; }
    .PE .texto .terminos {
      /*position: absolute;*/
      -webkit-transform: none;
              transform: none;
      bottom: 5%;
      right: 3%; }
      .PE .texto .terminos a {
        color: #0C273A;
        font-size: .8em; }
    @media all and (min-width: 1100px) {
      .PE .texto p.cb {
        font-size: 1.5em; }
      .PE .texto h1.mensaje {
        font-size: 2.8em;
        max-width: 530px !important; } }
    @media all and (max-width: 900px) {
      .PE .texto p.cb {
        font-size: 1em;
        margin-top: 50px !important; }
      .PE .texto h1.mensaje {
        font-size: 2em;
        margin-top: 25px !important; } }

.ES {
  background-image: url("/img/banner/Noviembre2020/structure/framents/vector3.png"), url("/img/banner/Noviembre2020/structure/framents/vector2.png"), url("/img/banner/Noviembre2020/structure/framents/vector1.png") !important;
  background-position: center top, center bottom, center;
  background-size: 100%, 100%, cover;
  background-repeat: no-repeat;
  background-color: #00a9e0; }
  .ES .texto {
    color: white;
    width: 100%;
    margin-left: 10px;
    text-align: center; }
    .ES .texto h1 {
      font-size: 2.2em;
      line-height: 1.4em;
      width: 100%;
      max-width: 360px;
      margin: auto;
      font-family: 'telefonicalight'; }
    .ES .texto p {
      text-align: center;
      border-radius: 25px;
      display: inline-block;
      color: white;
      font-family: "telefonicabold"; }
    .ES .texto .terminos {
      -webkit-transform: none;
              transform: none;
      bottom: 49%;
      right: -9%; }
      .ES .texto .terminos a {
        color: white;
        font-size: .8em; }
    @media all and (min-width: 1100px) {
      .ES .texto p.cb {
        font-size: 1.5em; }
      .ES .texto h1.mensaje {
        font-size: 2.8em;
        max-width: 530px !important; } }
    @media all and (max-width: 900px) {
      .ES .texto p.cb {
        font-size: 1em;
        margin-top: 50px !important; }
      .ES .texto h1.mensaje {
        font-size: 2em;
        margin-top: 25px !important; } }

.NA {
  background-image: url("/img/banner/agosto2020/footer.png"), url("/img/banner/agosto2020/widget.png"), url("/img/banner/agosto2020/vector.png"), url("/img/banner/agosto2020/mainbg.jpg");
  background-position: 7% 92%, 5% 55%, right bottom, center;
  background-size: 30% auto, 40% auto, 60% auto, cover;
  background-repeat: no-repeat; }
  .NA .texto {
    color: white;
    width: 100%;
    margin-left: 10px; }
    .NA .texto h1 {
      font-size: 2.2em;
      line-height: 1.3em;
      margin-bottom: 0;
      width: 90%;
      font-family: 'telefonicalight'; }
      @media all and (min-width: 1400px) {
        .NA .texto h1 {
          font-size: 3.4em; } }
    .NA .texto p {
      text-align: center;
      border-radius: 25px;
      display: inline-block;
      color: white;
      font-family: "telefonicabold"; }
    .NA .texto .terminos {
      position: absolute;
      bottom: 3%;
      left: 5%; }
      .NA .texto .terminos a {
        color: white;
        font-size: .8em; }

.PP {
  background: center bottom url("/img/banner/footer/footer.png"), left 75% url("/img/banner/Julio2020/turn-page/framents/mobile.png"), center bottom url("/img/banner/Julio2020/turn-page/framents/mountains.png"), center top url("/img/banner/Julio2020/turn-page/framents/wind.png") white;
  background-size: 100% auto, 100% auto, 100% auto, 100% auto !important;
  background-repeat: no-repeat; }

.fl {
  font-family: 'telefonicaextralight'; }

.fwb {
  font-family: "telefonicabold"; }

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box; }

html,
body {
  font-family: "telefonicaregular" !important;
  height: 100%;
  overflow: hidden;
  width: 100%;
  color: #50535a; }
  html input:disabled,
  body input:disabled {
    background: transparent; }

.ro {
  border: 1px solid red; }

.textoExclusivo {
  font-weight: 900 !important;
  font-size: 26px !important; }

.textoUnClick {
  font-weight: 100 !important;
  font-size: 50px !important;
  letter-spacing: -1px;
  line-height: 45px !important; }

.textoGratis {
  color: #5BC500 !important;
  font-size: 100px !important;
  line-height: 0.5em !important; }

.textoExclusivoWhite {
  color: white !important;
  font-weight: 900 !important;
  font-size: 26px !important;
  padding-top: 10px; }

.textoUnClickWhite {
  color: white !important;
  font-size: 60px !important;
  font-family: 'telefonicalight';
  letter-spacing: -1px;
  line-height: 60px !important; }

.textoGratisWhite {
  color: white !important;
  font-size: 60px !important;
  line-height: 0.5em !important; }

a, button, .cpointer {
  cursor: pointer; }
  a:focus, button:focus, .cpointer:focus {
    outline: none; }

button {
  background: transparent; }

div.cpointer:hover {
  text-decoration: underline; }

#app, .App, #root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto; }

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #bcbcbc; }

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #bfc3c6; }

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #f5f5f5; }

input {
  color: #86888c; }
  input.primary {
    padding: 0.8rem 0.75rem; }

.form-label-group {
  position: relative;
  margin-bottom: 1rem; }

.form-label-group label, .form-label-group input {
  padding: 12px 27px; }

.form-label-group input {
  background-color: transparent; }
  .form-label-group input::-webkit-input-placeholder {
    color: transparent; }
  .form-label-group input::-ms-input-placeholder {
    color: transparent; }
  .form-label-group input::-moz-placeholder {
    color: transparent; }
  .form-label-group input::placeholder {
    color: transparent; }
  .form-label-group input:not(:placeholder-shown) {
    padding-top: 27px;
    padding-bottom: 12px; }
  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 12px;
    color: #86888c; }
  .form-label-group input:disabled {
    background-color: transparent !important; }

.form-label-group label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  cursor: text;
  /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: .25rem;
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out; }

.imgr {
  width: 100%;
  height: auto;
  display: block; }

.imgb {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

.f01, .f02, .main-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center; }

.f02, .main-container {
  flex-flow: column nowrap; }

.algfs {
  align-items: flex-start; }

.algfe {
  align-items: flex-end; }

.algs {
  align-items: stretch; }

.jcfs {
  justify-content: flex-start; }

.jcfe {
  justify-content: flex-end; }

.jcsb {
  justify-content: space-between; }

.jcsa {
  justify-content: space-around; }

.tr, .btn-t, .btn-b, .btn-amount button, .home-cont .formulario .formulario-inicio .cont-campos, .home-cont .typePay, .scaleAndDown-enter-active, .scaleAndDown-exit-active, .fadeRight-enter-active, .fadeRight-exit-active, .fadeLeft-exit-active, .scale-enter-active, .scale-exit-active, .next-enter-active, .next-exit-active, .confirm-container .voltea, .fadeLeft-enter-active {
  -webkit-transition: all 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 500ms cubic-bezier(0.215, 0.61, 0.355, 1); }

.dn {
  display: none !important; }

.op {
  opacity: 1 !important; }

.cb {
  color: white; }

.cn {
  color: black; }

.bgg {
  background-color: #e4e4e4; }

.bgb {
  background-color: white; }

.bg-azul {
  background-color: #00a9e0 !important; }

.bgt {
  background-color: #5bc500 !important; }

.wc {
  width: 100%; }

.hc {
  height: 100%; }

.hvh {
  height: 100vh; }

.pa {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.brd {
  border-radius: 10px; }

.btn-round {
  border-radius: 25px; }

.cont-btn-t {
  line-height: 0; }

.btn-t {
  min-height: 50px;
  border-radius: 4px;
  background-color: #5bc500;
  color: white;
  font-size: 1.2em;
  line-height: 0 !important;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0); }
  .btn-t:hover {
    box-shadow: 0 0 0 0.2em rgba(0, 0, 0, 0.1); }

.btn-b {
  min-height: 50px;
  border-radius: 4px;
  border: 1px solid #5bc500;
  background-color: white;
  color: #5bc500;
  font-size: 1.2em;
  line-height: 0;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0); }
  .btn-b:hover {
    box-shadow: 0 0 0 0.2em rgba(0, 0, 0, 0.1); }

.btn-g {
  min-height: 50px;
  background: white; }

.loading {
  position: relative; }
  .loading:after, .loading:before {
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.3); }
  .loading:after {
    border-right: 3px solid white;
    -webkit-animation: girar .75s linear infinite;
            animation: girar .75s linear infinite; }

.loading3 {
  position: relative; }
  .loading3:after, .loading3:before {
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 3px solid rgba(91, 197, 0, 0.3); }
  .loading3:after {
    border-right: 3px solid #5bc500;
    -webkit-animation: girar .75s linear infinite;
            animation: girar .75s linear infinite; }

.campo-icon {
  position: relative; }
  .campo-icon input.icon-padding {
    padding-left: 40px; }
  .campo-icon input {
    line-height: 45px;
    height: 47px;
    border-radius: 4px;
    background-color: #e9eae8;
    border: 1px solid #e9eae8;
    width: 100%; }
    .campo-icon input:focus {
      outline: none;
      background-color: white; }
  .campo-icon input[type="checkbox"] {
    height: 15px;
    max-height: 15px;
    width: 15px;
    border: 1px solid red;
    padding: 0;
    cursor: pointer;
    line-height: 15px !important;
    position: relative;
    z-index: 2;
    opacity: 0; }
  .campo-icon .cont-chulo {
    width: 15px;
    margin: auto;
    position: relative; }
  .campo-icon .chulo {
    width: 15px;
    height: 15px;
    box-shadow: 0 0 0 0.23em #00a9e0;
    border-radius: 50%;
    position: absolute;
    left: 0;
    z-index: 1;
    top: 5px; }
  .campo-icon .chuleado {
    background-image: url("/assets/images/check.png");
    background-color: #00a9e0; }
    .campo-icon .chuleado:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 0 0 0.23em #00a9e0;
      border-radius: 50%;
      -webkit-animation: onda 0.5s cubic-bezier(0.19, 1, 0.22, 1) 1;
              animation: onda 0.5s cubic-bezier(0.19, 1, 0.22, 1) 1; }

@-webkit-keyframes onda {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  to {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0; } }

@keyframes onda {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  to {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0; } }
  .campo-icon .icon {
    width: 40px;
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    text-align: center;
    line-height: 50px;
    z-index: 16; }
  .campo-icon .error-icon {
    color: red; }
  .campo-icon .error-input {
    background: white;
    border-color: red;
    color: red; }
    .campo-icon .error-input::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: red;
      opacity: .5;
      /* Firefox */ }
    .campo-icon .error-input::-moz-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: red;
      opacity: .5;
      /* Firefox */ }
    .campo-icon .error-input::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: red;
      opacity: .5;
      /* Firefox */ }
    .campo-icon .error-input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: red;
      opacity: .5;
      /* Firefox */ }
    .campo-icon .error-input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: red;
      opacity: .5;
      /* Firefox */ }
    .campo-icon .error-input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: red;
      opacity: .5;
      /* Firefox */ }
  .campo-icon .error {
    font-style: italic;
    display: block;
    width: 100%;
    text-align: left;
    color: red; }
  .campo-icon .react-select__control, .campo-icon .select-error__control {
    background-color: #e9eae8;
    border-color: #e9eae8;
    position: relative;
    z-index: 10;
    max-height: 50px; }
    .campo-icon .react-select__control:hover, .campo-icon .select-error__control:hover {
      border-color: #e9eae8; }
  .campo-icon .select-error__control {
    border-color: red;
    background-color: white; }
  .campo-icon .react-select__control--is-focused, .campo-icon .select-error__control--is-focused {
    background-color: white;
    border-color: #e9eae8;
    border-width: 1px;
    box-shadow: none; }
    .campo-icon .react-select__control--is-focused:hover, .campo-icon .select-error__control--is-focused:hover {
      border-color: #e9eae8; }
  .campo-icon .select-error__control--is-focused {
    border-color: red; }
  .campo-icon .react-select__value-container, .campo-icon .select-error__value-container {
    max-height: 50px;
    padding-left: 40px; }
  .campo-icon .select-error__placeholder {
    color: red; }
  .campo-icon .react-select__menu,
  .campo-icon .react-select__dropdownIndicator {
    text-align: left;
    z-index: 22; }

.ciudad {
  position: relative;
  z-index: 100; }

@-webkit-keyframes girar {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0);
            transform: translate(-50%, -50%) rotate(0); }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes girar {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0);
            transform: translate(-50%, -50%) rotate(0); }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg); } }

.loadingData {
  border-radius: 4px;
  width: 100%;
  display: block;
  background: -webkit-gradient(linear, left top, right top, from(#e4e4e4), color-stop(white), to(#e4e4e4));
  background: -webkit-linear-gradient(left, #e4e4e4, white, #e4e4e4);
  background: linear-gradient(90deg, #e4e4e4, white, #e4e4e4);
  background-size: 600% 600%;
  -webkit-animation: loadingResponse .5s linear infinite;
  animation: loadingResponse .5s linear infinite; }

@-webkit-keyframes loadingResponse {
  0% {
    background-position: 0% 50%; }
  100% {
    background-position: 100% 50%; } }

@keyframes loadingResponse {
  0% {
    background-position: 0% 50%; }
  100% {
    background-position: 100% 50%; } }

.fc-gray {
  color: #50535a; }

.fc-silver {
  color: #b6b7b7; }

.cv {
  color: #5bc500; }

.ca {
  color: #00a9e0; }

.cg {
  color: #cbcbcb; }

.cy {
  color: orange; }

.cr {
  color: red; }

.bgv {
  background-color: #5bc500; }

.bga {
  background-color: #00a9e0; }

.opn {
  opacity: 0; }

header {
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  z-index: 20; }
  header .logo-movistar img {
    max-height: 35px;
    width: auto;
    height: auto; }

.btn-amount {
  margin: .5em;
  position: relative;
  z-index: 0; }
  .btn-amount img {
    position: relative;
    z-index: 0; }
  .btn-amount button {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    cursor: pointer; }
    .btn-amount button:hover {
      background: #5bc500;
      color: white;
      border: 1px solid transparent; }
    .btn-amount button:disabled {
      background: rgba(0, 0, 0, 0.01);
      color: #999999;
      cursor: not-allowed;
      border: 1px solid #e4e4e4; }
    .btn-amount button p {
      margin: 0; }

.max350 {
  max-width: 350px; }

.btn-amount.active button {
  background-color: #5bc500;
  cursor: auto;
  cursor: initial;
  color: white; }
  .btn-amount.active button:disabled {
    border-color: transparent; }

.error {
  font-style: italic;
  display: block;
  width: 100%;
  text-align: left;
  color: red; }

.metodosIcons img {
  max-height: 22px;
  margin-left: 5px; }

.bala01 {
  width: 50px;
  height: 4px;
  background-color: #5bc500;
  border-radius: 10px;
  -webkit-animation: Chargin .5s linear infinite;
          animation: Chargin .5s linear infinite; }

.bala02 {
  width: 50px;
  height: 3px;
  background-color: #00a9e0;
  border-radius: 10px;
  margin-bottom: 3px;
  -webkit-animation: Chargin .7s linear infinite;
          animation: Chargin .7s linear infinite; }

.bala03 {
  width: 30px;
  height: 6px;
  margin-bottom: 5px;
  background-color: #5bc500;
  border-radius: 10px;
  -webkit-animation: Chargin .6s linear infinite;
          animation: Chargin .6s linear infinite; }

@-webkit-keyframes Chargin {
  0% {
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
    opacity: 0; }
  50% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(200px);
            transform: translateX(200px);
    opacity: 0; } }

@keyframes Chargin {
  0% {
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
    opacity: 0; }
  50% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(200px);
            transform: translateX(200px);
    opacity: 0; } }

/*Circulos*/
.LoaderBalls {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.LoaderBalls__item {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #5BC500;
  -webkit-transform: 0.5s;
          transform: 0.5s; }

.LoaderBalls__item:nth-child(1) {
  -webkit-animation: bouncing 0.4s alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95);
          animation: bouncing 0.4s alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95);
  background: #b4fa76; }

.LoaderBalls__item:nth-child(2) {
  -webkit-animation: bouncing 0.4s 0.1s alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;
          animation: bouncing 0.4s 0.1s alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;
  background: #77db1f; }

.LoaderBalls__item:nth-child(3) {
  -webkit-animation: bouncing 0.4s 0.2s alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;
          animation: bouncing 0.4s 0.2s alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;
  background: #5BC500; }

@-webkit-keyframes bouncing {
  0% {
    -webkit-transform: translate3d(0, 10px, 0) scale(1.2, 0.85);
            transform: translate3d(0, 10px, 0) scale(1.2, 0.85); }
  100% {
    -webkit-transform: translate3d(0, -20px, 0) scale(0.9, 1.1);
            transform: translate3d(0, -20px, 0) scale(0.9, 1.1); } }

@keyframes bouncing {
  0% {
    -webkit-transform: translate3d(0, 10px, 0) scale(1.2, 0.85);
            transform: translate3d(0, 10px, 0) scale(1.2, 0.85); }
  100% {
    -webkit-transform: translate3d(0, -20px, 0) scale(0.9, 1.1);
            transform: translate3d(0, -20px, 0) scale(0.9, 1.1); } }

#cont-modal {
  position: fixed;
  z-index: 22;
  top: 0; }

.modal-container {
  position: fixed;
  z-index: 2;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0; }
  .modal-container .sombra-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0; }
  .modal-container .masOscuro {
    background-color: rgba(0, 0, 0, 0.8); }
  .modal-container .area {
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-perspective: 1500px;
            perspective: 1500px; }
  .modal-container .card, .modal-container .gira {
    position: relative;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    opacity: 0;
    -webkit-transform: scale(0.8) rotateY(-180deg);
            transform: scale(0.8) rotateY(-180deg);
    -webkit-transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
    .modal-container .card .close, .modal-container .gira .close {
      font-size: 14px;
      position: absolute;
      top: .5em;
      right: .5em;
      cursor: pointer; }
    .modal-container .card .close2, .modal-container .gira .close2 {
      width: 20px;
      top: .75em;
      right: .75em; }
  .modal-container .gira {
    box-shadow: none; }

.fade-enter {
  opacity: 0; }

.fade-enter-active {
  opacity: 1;
  -webkit-transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1); }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  opacity: 0;
  -webkit-transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1); }

.fade-enter-done .card, .fade-enter-done .gira {
  opacity: 1;
  -webkit-transform: scale(1) rotateY(0);
          transform: scale(1) rotateY(0); }

.fade-enter-done .cColombia {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1; }

.fade-exit .card, .fade-exit .gira {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9); }

.fade-exit .cColombia {
  opacity: 0;
  -webkit-transform: scale(0.5);
          transform: scale(0.5); }

.desenfocar {
  -webkit-filter: blur(1.5rem);
          filter: blur(1.5rem); }

.main-container {
  width: 100%;
  min-height: 100%;
  height: auto;
  justify-content: flex-start; }
  .main-container .content-main {
    flex: 1 1 auto;
    width: 100%; }
    .main-container .content-main .home-container {
      position: relative;
      background-image: url("/../assets/images/home.jpg");
      min-height: calc(100vh - 51px); }

.sombra {
  z-index: 1;
  background: white; }

.home-cont {
  height: 100vh;
  position: relative;
  z-index: 2; }

.confirm-container,
.home-content,
.response-container {
  position: relative;
  z-index: 2;
  height: calc(100vh - 51px); }

.home-cont .formulario {
  position: relative;
  height: 100%;
  z-index: 0; }
  .home-cont .formulario .selectTypePay, .home-cont .formulario .formulario-inicio {
    background: white;
    padding: .75em;
    height: 100%;
    overflow: hidden;
    margin: auto;
    z-index: 0;
    -webkit-transform: translateZ(0);
            transform: translateZ(0); }
  .home-cont .formulario .formulario-inicio {
    will-change: transform; }
    .home-cont .formulario .formulario-inicio form {
      max-width: 350px; }
    .home-cont .formulario .formulario-inicio .titu-form {
      flex: 1 1 auto;
      max-width: 350px; }
    .home-cont .formulario .formulario-inicio .titulo-opciones {
      flex: 0 0 auto;
      position: relative;
      z-index: 0;
      -webkit-transform: translateZ(0);
              transform: translateZ(0); }
      .home-cont .formulario .formulario-inicio .titulo-opciones .opciones button, .home-cont .formulario .formulario-inicio .titulo-opciones .opciones2 button {
        line-height: 35px;
        flex: 1 0 70px;
        padding: 0 .75em;
        margin: 0 .5em;
        overflow: hidden;
        position: relative;
        z-index: 0;
        -webkit-transform: translateZ(0);
                transform: translateZ(0); }
        .home-cont .formulario .formulario-inicio .titulo-opciones .opciones button:hover, .home-cont .formulario .formulario-inicio .titulo-opciones .opciones2 button:hover {
          background-color: rgba(0, 0, 0, 0.05); }
        .home-cont .formulario .formulario-inicio .titulo-opciones .opciones button:disabled:hover, .home-cont .formulario .formulario-inicio .titulo-opciones .opciones2 button:disabled:hover {
          background-color: #00a9e0; }
      .home-cont .formulario .formulario-inicio .titulo-opciones .opciones2 button {
        line-height: 35px;
        flex: 1 1 auto; }
    .home-cont .formulario .formulario-inicio .cont-campos {
      position: relative;
      will-change: contents;
      z-index: 0;
      -webkit-transform: translateZ(0);
              transform: translateZ(0); }
      .home-cont .formulario .formulario-inicio .cont-campos .fijo button {
        line-height: 35px;
        border-radius: 0 0 4px 4px;
        border-top: 4px solid #00a9e0; }
      .home-cont .formulario .formulario-inicio .cont-campos .fijo button.active {
        background-color: #00a9e0;
        color: white; }
    .home-cont .formulario .formulario-inicio .form-footer img {
      max-height: 30px;
      margin-left: .5em; }
  .home-cont .formulario .selectTypePay a {
    color: #00a9e0;
    cursor: pointer; }
    .home-cont .formulario .selectTypePay a:hover {
      text-decoration: underline; }
  .home-cont .formulario .selectTypePay .opciones {
    flex: 1 1 300px;
    max-width: 550px;
    width: 100%;
    margin: auto; }
  .home-cont .formulario .selectTypePay .title {
    flex: 0 0 50px; }
  .home-cont .formulario .selectTypePay .enlace {
    flex: 0 0 30px; }

.home-cont .textos-iconos {
  position: relative;
  z-index: 0;
  -webkit-transform: translateZ(0);
          transform: translateZ(0); }
  .home-cont .textos-iconos h1 {
    font-size: 35px; }
  .home-cont .textos-iconos .icono {
    flex: 1 1 45%;
    padding: .5em 0; }
    .home-cont .textos-iconos .icono .icon {
      max-width: 40px;
      margin: auto; }
    .home-cont .textos-iconos .icono .texto {
      font-size: 13px;
      line-height: 16px; }

.home-cont .typePay {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0 0 4px 4px;
  border-top: 4px solid #e4e4e4;
  cursor: pointer;
  -webkit-transform: scale(1) translateZ(0);
          transform: scale(1) translateZ(0);
  will-change: trasform;
  position: relative;
  z-index: 0; }
  .home-cont .typePay:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); }
  .home-cont .typePay p {
    line-height: .95em; }
  .home-cont .typePay small {
    color: #999999; }

.home-cont .bcv {
  border-color: #5BC500; }

.home-cont .bcm {
  border-color: #954B98; }

.home-cont .bcr {
  border-color: #EC6838; }

.scaleAndDown-enter {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  opacity: 0; }

.scaleAndDown-enter-active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1; }

.scaleAndDown-exit {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1; }

.scaleAndDown-exit-active {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  opacity: 0; }

.fadeRight-enter {
  opacity: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  z-index: 2;
  position: relative; }

.fadeRight-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  z-index: 2;
  position: relative; }

.fadeRight-exit {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  z-index: 1; }

.fadeRight-exit-active {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  z-index: 1; }

.fadeLeft-exit {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  z-index: 1; }

.fadeLeft-exit-active {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  z-index: 1; }

.scale-enter {
  -webkit-transform: scale(0.9) !important;
          transform: scale(0.9) !important;
  opacity: 0;
  z-index: 1;
  position: relative; }

.scale-enter-active {
  opacity: 1;
  -webkit-transform: scale(1) !important;
          transform: scale(1) !important;
  z-index: 1;
  position: relative; }

.scale-exit {
  -webkit-transform: translate(-50%, -50%) scale(1) !important;
          transform: translate(-50%, -50%) scale(1) !important;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%; }

.scale-exit-active {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%) scale(0.9) !important;
          transform: translate(-50%, -50%) scale(0.9) !important; }

.next-enter {
  -webkit-transform: translate(-100%) !important;
          transform: translate(-100%) !important;
  z-index: 10;
  opacity: 0; }

.next-enter-active {
  -webkit-transform: translate(0) !important;
          transform: translate(0) !important;
  z-index: 10;
  opacity: 1; }

.next-exit {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 10;
  opacity: 1;
  -webkit-transform: translate(0, -50%) !important;
          transform: translate(0, -50%) !important;
  height: auto !important; }

.next-exit-active {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 10;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
  height: auto !important; }

.confirm-container {
  justify-content: flex-start; }
  .confirm-container .container {
    flex: 1 1 auto;
    justify-content: flex-start; }
  .confirm-container .cont-top h1 {
    font-size: 25px;
    font-family: "Telefonica-lihgt"; }
  .confirm-container .cont-top .number-client {
    width: 100%;
    max-width: 250px;
    margin: auto;
    border-bottom: 2px solid #496d73; }
    .confirm-container .cont-top .number-client .icono {
      flex: 0 0 50px; }
    .confirm-container .cont-top .number-client .number-c {
      flex: 0 0 auto;
      text-align: left; }
      .confirm-container .cont-top .number-client .number-c small {
        display: block;
        text-transform: uppercase;
        font-size: 10px; }
      .confirm-container .cont-top .number-client .number-c span {
        font-size: 1.3em; }
  .confirm-container .detalle-titulos {
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4; }
  .confirm-container .title {
    border-top: 1px solid #e4e4e4;
    font-size: 20px;
    font-family: Telefonica-lihgt; }
  .confirm-container .table {
    border-bottom: 1px solid #cbcbcb; }
  .confirm-container .servicio {
    border-bottom: 1px solid #e4e4e4; }
    .confirm-container .servicio:last-child {
      border: none; }
    .confirm-container .servicio .amount {
      padding: .5em;
      border-radius: 5px;
      background: #e4e4e4;
      color: black;
      cursor: pointer; }
      .confirm-container .servicio .amount:focus {
        outline: none; }
      .confirm-container .servicio .amount:disabled {
        opacity: .8;
        color: #999999; }
  .confirm-container .voltea {
    -webkit-transform: rotateZ(-180deg);
            transform: rotateZ(-180deg); }
  .confirm-container .main-card {
    flex: 1 1 auto; }
  .confirm-container .totalToPay {
    display: inline;
    color: black;
    font-weight: bold;
    width: 80px;
    margin-left: .5em;
    flex: 0 0 auto; }
  .confirm-container .totaltexto {
    width: 100px;
    flex: 0 0 auto; }
  .confirm-container .cont-franquicias {
    flex: 0 0 auto; }
    .confirm-container .cont-franquicias .franquicias img {
      height: 30px;
      width: auto;
      margin: 0 3px; }

.response-container section .titulo {
  font-size: 20px; }

.response-container section .tab {
  border-top: 5px solid #00a9e0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 5px 5px; }
  .response-container section .tab .line span {
    font-size: 12px; }
  .response-container section .tab .line p {
    line-height: 1em;
    word-break: break-all; }

.response-container .section-green .tab {
  border-radius: 0;
  border-top: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0); }
  .response-container .section-green .tab .banner {
    border-top: 5px solid #5bc500;
    border-bottom: 1px solid #e4e4e4;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); }

.response-container .section-green .otros-datos {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); }

.response-container .section-green button {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); }

.fadeLeft-enter {
  opacity: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  position: relative;
  z-index: 2; }

.fadeLeft-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  position: relative;
  z-index: 2; }

.procesing {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  position: relative;
  height: 5px;
  border-radius: 4px;
  overflow: hidden; }
  .procesing .charge {
    width: 25px;
    border-radius: 2px;
    height: 100%;
    background-color: #5bc500;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sidetoside .5s linear infinite alternate;
            animation: sidetoside .5s linear infinite alternate; }

.barloading {
  width: 100%;
  height: 5px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1); }
  .barloading .color {
    height: 100%;
    background-color: #5bc500;
    -webkit-transition: all 1s linear;
    transition: all 1s linear; }

@-webkit-keyframes sidetoside {
  from {
    left: 0; }
  to {
    left: calc(100% - 25px); } }

@keyframes sidetoside {
  from {
    left: 0; }
  to {
    left: calc(100% - 25px); } }

.modal-nuevo {
  background-image: url("/img/iconos/sticker.svg"), url("/img/iconos/heart.svg");
  background-repeat: no-repeat,no-repeat;
  background-position: 105% -30px, 0 105%;
  background-size: 15% auto, 10% auto; }

.barra-loading {
  height: 10px;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #5bc500; }
  .barra-loading .charge {
    height: 100%;
    border-radius: 5px;
    background-color: #5bc500; }

@media (min-width: 768px) {
  .home-recarga {
    background-image: url("/img/iconos/sticker.svg"), url("/img/iconos/heart.svg");
    background-repeat: no-repeat,no-repeat;
    background-position: 45% -13%, 0 110%;
    background-size: 12% auto, 10% auto;
    height: calc(100vh - 67px);
    overflow: hidden;
    overflow-y: auto; }
    .home-recarga .cont-form {
      min-height: 100%;
      justify-content: flex-start; }
  .main-container .content-main {
    min-height: calc(100vh - 67px); }
    .main-container .content-main .home-container {
      min-height: calc(100vh - 67px);
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center; }
      .main-container .content-main .home-container .curva {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        z-index: 2;
        overflow: hidden;
        background-image: url("/../assets/images/curva.png");
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: 100% 20vh; }
  .sombra {
    z-index: 1;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.7+0,0.29+100;Neutral+Density */
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.29) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.7)), to(rgba(0, 0, 0, 0.29)));
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.29) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3000000', endColorstr='#4a000000',GradientType=0 );
    /* IE6-9 */ }
  .home-cont,
  .confirm-container,
  .response-container {
    height: calc(100vh - 67px);
    position: relative;
    z-index: 3; }
  .home-cont .formulario {
    height: auto; }
    .home-cont .formulario .formulario-inicio {
      height: auto;
      border-radius: 5px;
      max-width: 350px; }
  .selectTypePay {
    max-width: 350px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); }
  .confirm-container .container {
    justify-content: center; }
  .confirm-container .main-card {
    margin: 1em;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 5px 5px;
    flex: 0 0 auto; }
  .confirm-container .cont-services {
    max-height: 204px;
    overflow: hidden;
    overflow-y: auto; } }

@media all and (min-width: 768px) and (min-height: 700px) {
  .confirm-container {
    justify-content: center; } }

@media all and (min-width: 768px) and (min-height: 660px) {
  .response-container {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center; } }

@media (min-width: 992px) {
  .home-cont .textos-iconos .icono {
    flex: 1 1 24%;
    padding: 0; } }

